import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import io from 'socket.io-client';

const api = "https://server.techu.in"

const socket = io(api, {
    transports: ['websocket'],
    cors: {
        origin: 'https://test.techu.in'
    }
});
const DemoRegisterList = () => {
    const [data, setData] = useState([]);
    const [filterDate, setFilterDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        socket.on('connect', () => { });

        socket.on('disconnect', () => { });

        socket.on('new demo registration', (data) => { });

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('new demo registration');
        };
    }, []);

    useEffect(() => {
        if (Notification.permission === 'default') {
            Notification.requestPermission();
        }

        axios.get(api + '/all')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setData(response.data);
                    setCourses([...new Set(response.data.map(item => item.course))]);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });

        const today = new Date().toISOString().split('T')[0];
        setMaxDate(today);

        socket.on('new demo registration', () => {
            showNotificationAndPlaySound();
        });

        return () => {
            socket.off('new demo registration');
        };
    }, []);

    const showNotificationAndPlaySound = () => {
        if (Notification.permission === 'granted') {
            new Notification('New Demo Registration Alert', {
                body: 'A new user has just signed up for a demo session. Click to view the registration details.',
                icon: './logo.png',
            });
        } else if (Notification.permission === 'default') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    new Notification('New Demo Registration Alert', {
                        body: 'A new user has just signed up for a demo session. Click to view the registration details.',
                        icon: './logo.png',
                    });
                } else {
                    displayNotificationPermissionMessage();
                }
            }).catch(error => {
                displayNotificationPermissionMessage();
            });
        } else {
            displayNotificationPermissionMessage();
        }
    };

    const displayNotificationPermissionMessage = () => {
        let notificationMessage = document.getElementById('notification-permission-message');
        if (!notificationMessage) {
            notificationMessage = document.createElement('div');
            notificationMessage.id = 'notification-permission-message';
            notificationMessage.style.position = 'fixed';
            notificationMessage.style.bottom = '20px';
            notificationMessage.style.left = '20px';
            notificationMessage.style.padding = '10px';
            notificationMessage.style.backgroundColor = '#f8d7da';
            notificationMessage.style.color = '#721c24';
            notificationMessage.style.border = '1px solid #f5c6cb';
            notificationMessage.style.borderRadius = '4px';
            notificationMessage.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            notificationMessage.style.zIndex = '1000';
            document.body.appendChild(notificationMessage);
        }
        notificationMessage.textContent = 'Notification permissions are not granted. Please enable notifications in your browser settings to receive updates.';
    };

    const handleDateChange = (event) => {
        setFilterDate(event.target.value);
        setCurrentPage(1);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
        setCurrentPage(1);
    };

    const filteredData = data?.filter((item) => {
        const itemDate = new Date(item.createdAt).toISOString().split('T')[0];
        return (!filterDate || itemDate === filterDate) && (!selectedCourse || item.course === selectedCourse);
    });

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const currentData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);



    const FormattedTime = (utcDateString) => {
        const utcDate = new Date(utcDateString);
        const options = {
            timeZone: 'Asia/Kolkata',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        const formattedTime = new Intl.DateTimeFormat('en-US', options).format(utcDate);

        return formattedTime;
    };

    const FormattedDate = (date) => {
        const createdAtDate = new Date(date);
        return `${createdAtDate.getUTCDate().toString().padStart(2, '0')}/${(createdAtDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${createdAtDate.getUTCFullYear()}`;
    }

    const exportToExcel = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const fileName = `${selectedCourse ? selectedCourse : "Demo_Registrations"}_${filterDate ? filterDate : new Date().toISOString().split('T')[0]}`;

        const filteredRowsData = filteredData.map(({ name, mobile, education, nativeState, course, createdAt }, index) => [
            index + 1,
            name,
            mobile,
            education,
            nativeState,
            course,
            FormattedDate(createdAt),
            FormattedTime(createdAt)
        ]);

        const ws = XLSX.utils.aoa_to_sheet([['S.No', 'Name', 'Mobile Number', 'Highest Education', 'State', 'course', 'Registered At', 'Time'], ...filteredRowsData]);

        const headerCells = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1'];
        headerCells.forEach(cell => {
            if (ws[cell]) {
                ws[cell].s = { font: { bold: true } };
            }
        });

        ws['!cols'] = [{ wpx: 50 }, { wpx: 200 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName + fileExtension;
        link.click();
    };

    const clearFilter = () => {
        setFilterDate("");
        setSelectedCourse("");
        setItemsPerPage(10);
        setCurrentPage(1);
    };
    const totalEntries = filterDate || selectedCourse ? filteredData.length : data.length;

    return (
        <div style={{
            padding: '20px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            margin: 'auto',
            boxSizing: 'border-box'
        }}>
            <h2 style={{ marginBottom: '20px', fontSize: '24px', color: '#333', textAlign: 'center' }}>Demo Register List</h2>
            <div className='sticky-top' style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: "space-around",
                alignItems: 'flex-start',
                flexWrap: "wrap",
                gap: '10px',
                backgroundColor: "#ffffff"
            }}>
                <div>
                    <label htmlFor="filterDate" style={{ fontSize: '16px', color: '#555' }}>Filter by Date:</label>
                    <input
                        type="date"
                        id="filterDate"
                        value={filterDate}
                        onChange={handleDateChange}
                        max={maxDate}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            width: '100%',
                            maxWidth: '250px',
                            boxSizing: 'border-box'
                        }}
                    />
                </div>
                <div style={{ minWidth: '150px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label htmlFor="selectedCourse" style={{ fontSize: '16px', color: '#555' }}>Course:</label>
                    <select
                        id="selectedCourse"
                        value={selectedCourse}
                        onChange={handleCourseChange}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            width: '100%',
                            maxWidth: '150px',
                            boxSizing: 'border-box'
                        }}
                    >
                        <option value="">All</option>
                        {courses.map((course, index) => (
                            <option key={index} value={course}>{course}</option>
                        ))}
                    </select>
                </div>
                <div style={{ flex: '1 1 200px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <label htmlFor="itemsPerPage" style={{ fontSize: '16px', color: '#555' }}>Items per Page:</label>
                    <select
                        id="itemsPerPage"
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        style={{
                            padding: '10px',
                            fontSize: '16px',
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            width: '100%',
                            maxWidth: '150px',
                            boxSizing: 'border-box'
                        }}
                    >
                        {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(number => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <button
                        onClick={clearFilter}
                        style={{
                            marginTop: '22px',
                            padding: '10px 15px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            backgroundColor: '#c76a97',
                            color: '#fff',
                            cursor: 'pointer',
                            border: 'none'
                        }}
                    >
                        Clear Filter
                    </button>
                </div>
                <div>
                    <button
                        onClick={exportToExcel}
                        style={{
                            marginTop: '22px',
                            padding: '10px 15px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            backgroundColor: '#c76a97',
                            color: '#fff',
                            cursor: 'pointer',
                            border: 'none'
                        }}
                    >
                        Download in Excel Report
                    </button>
                </div>
            </div>

            <h1 className='sticky-top' style={{
                fontSize: "20px",
                color: "rgb(51, 51, 51)",
                textAlign: "center",
            }}>
                <span style={{
                    backgroundColor: "rgb(255, 255, 255)", padding: "0px 20px 0px 20px"
                }}>Total entries {selectedCourse ? `of ${selectedCourse}` : ""} {filterDate ? `on ${filterDate}` : ""}: {totalEntries}
                </span>
            </h1>

            <div style={{ overflowX: 'auto' }}>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    borderRadius: '8px',
                    overflow: 'hidden'
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#f2f2f2',
                            textAlign: 'left'
                        }}>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>S.No</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>Name</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>Mobile Number</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>Highest Education</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>State</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>Course</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>Registered At</th>
                            <th style={{
                                padding: '12px',
                                borderBottom: '1px solid #ddd',
                                color: '#333',
                                fontSize: '14px'
                            }}>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentData.map((item, index) => (
                            <tr key={index}>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{item.name}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{item.mobile}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{item.education}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{item.nativeState}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{item.course}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{FormattedDate(item.createdAt)}</td>
                                <td style={{
                                    padding: '12px',
                                    borderBottom: '1px solid #ddd',
                                    color: '#555',
                                    fontSize: '14px'
                                }}>{FormattedTime(item.createdAt)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{
                marginTop: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
                    }}
                    aria-label="Previous Page"
                >
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages || currentData.length === 0}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        backgroundColor: '#fff',
                        cursor: (currentPage === totalPages || currentData.length === 0) ? 'not-allowed' : 'pointer'
                    }}
                    aria-label="Next Page"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default DemoRegisterList;