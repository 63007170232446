import './App.css';
import DemoRegisterList from "./DemoRegisterList"
function App() {
  return (
    <div className="App">
      <DemoRegisterList />
    </div>
  );
}

export default App;
